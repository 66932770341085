@import "./Colors.scss";

.btn-custom-1 {
    outline:none !important;
    box-shadow: none !important;
    background-color: white;
    border-radius: 30px;
    

    &:hover {
        outline: none !important;
        box-shadow: none !important;
    }

    &:focus {
        outline: none !important;
        box-shadow: none !important;
    }

    &:active {
        outline: none !important;
        box-shadow: none !important;
    }
}

.btn-custom-1-opacity {
    outline:none !important;
    box-shadow: none !important;
    background-color: rgba(255,255,255,0.7);
    border-radius: 30px;
    
    

    &:hover {
        outline: none !important;
        box-shadow: none !important;
    }

    &:focus {
        outline: none !important;
        box-shadow: none !important;
    }

    &:active {
        outline: none !important;
        box-shadow: none !important;
    }
}


.btn-custom-2 {
    outline:none !important;
    box-shadow: none !important;
    background-color: rgb(72,107,231);
    border-radius: 30px;

    span {
        color:white;
    }

    i {
        color:white;
    }


    &:hover {
        outline: none !important;
        box-shadow: none !important;
    }

    &:focus {
        outline: none !important;
        box-shadow: none !important;
    }

    &:active {
        outline: none !important;
        box-shadow: none !important;
    }
}

.btn-custom-3 {
    outline:none !important;
    box-shadow: none !important;
    background-color: rgba(32,222,190,1);
    border-radius: 30px;

    span {
        color:white;
    }

    i {
        color:white;
    }


    &:hover {
        outline: none !important;
        box-shadow: none !important;
    }

    &:focus {
        outline: none !important;
        box-shadow: none !important;
    }

    &:active {
        outline: none !important;
        box-shadow: none !important;
    }
}

@media screen and (max-width:320px){
    .btn-custom-1 {
        outline:none !important;
        box-shadow: none !important;
        background-color: white;
        border-radius: 30px;
        padding: 4px !important;
        
    
        &:hover {
            outline: none !important;
            box-shadow: none !important;
        }
    
        &:focus {
            outline: none !important;
            box-shadow: none !important;
        }
    
        &:active {
            outline: none !important;
            box-shadow: none !important;
        }
    }

    .btn-custom-1-opacity {
        outline:none !important;
        box-shadow: none !important;
        background-color: rgba(255,255,255,0.7);
        border-radius: 30px;
        padding: 4px !important;
        
        
    
        &:hover {
            outline: none !important;
            box-shadow: none !important;
        }
    
        &:focus {
            outline: none !important;
            box-shadow: none !important;
        }
    
        &:active {
            outline: none !important;
            box-shadow: none !important;
        }
    }
    
}