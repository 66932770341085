@import "./Animations.scss";
@import "./Boilerpolate.scss";
@import "./Buttons.scss";
@import "./Colors.scss";
@import "./Flex-tools.scss";
@import "./Loaders.scss";
@import "./Navbar.scss";
@import "./Sections.scss";

@import "~normalize.css";
@import "~@blueprintjs/core/lib/css/blueprint.css";
@import "~@blueprintjs/icons/lib/css/blueprint-icons.css";
@import "~@blueprintjs/datetime/lib/css/blueprint-datetime.css";

::-webkit-scrollbar
    {
      width: 10px;  /* for vertical scrollbars */
      height: 6px; /* for horizontal scrollbars */
    }
    
    ::-webkit-scrollbar-track
    {
      background: rgba(0, 0, 0, 0.1);
    }
    
    ::-webkit-scrollbar-thumb
    {
      background: rgba(0, 0, 0, 0.2);
    }

    body {
      p {
        font-size: 18px;
        color:$mediumGrey;
        line-height: 1.5;
      }
    }


    html {
      scroll-behavior: smooth;
    }