@import "./Colors.scss";

.loading-feedback {
    background-color: rgba(0,0,0,0.6);
    position: fixed;
    top:0;
    left: 0;
    z-index: 16;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;

    h3 {
      animation-name: text-beating;
      animation-iteration-count: infinite;
      animation-duration: 1s;
      color: white;
    }

    @keyframes text-beating {
      0%{color: white;}
      50%{color: rgba(255,255,255, 0.5);}
      100%{color: white;}
    }
  }

  .circle-loader {
    position: fixed;
    bottom: 20px;
    right: 20px;

    .lds-ring {
        display: inline-block;
        position: relative;
        width: 80px;
        height: 80px;
      }
      .lds-ring div {
        box-sizing: border-box;
        display: block;
        position: absolute;
        width: 64px;
        height: 64px;
        margin: 8px;
        border: 8px solid $darkGrey;
        border-radius: 50%;
        animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
        border-color: $darkGrey transparent transparent transparent;
      }
      .lds-ring div:nth-child(1) {
        animation-delay: -0.45s;
      }
      .lds-ring div:nth-child(2) {
        animation-delay: -0.3s;
      }
      .lds-ring div:nth-child(3) {
        animation-delay: -0.15s;
      }
      @keyframes lds-ring {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(360deg);
        }
      }
    }

    .lds-dual-ring {
      display: inline-block;
      width: 80px;
      height: 80px;
    }
    .lds-dual-ring:after {
      content: " ";
      display: block;
      width: 64px;
      height: 64px;
      margin: 8px;
      border-radius: 50%;
      border: 6px solid $primaryColor;
      border-color: $primaryColor transparent $primaryColor transparent;
      animation: lds-dual-ring 1.2s linear infinite;
    }
    @keyframes lds-dual-ring {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }

    .position-center {
      position: fixed;
      left: 50%;
      top: 50%;
      transform: translate(-50%,-50%);
    }