@import "./Colors.scss";

.form-control-1 {
    width: 200px !important;
    border:none !important;
    outline: none !important;
    box-shadow: none !important;
    background-color: $lightGrey;

    &:focus {
        outline: none !important;
    box-shadow: none !important;
    background-color: $lightGrey;
    }

    &:hover {
        outline: none !important;
    box-shadow: none !important;
    background-color: $lightGrey;
    }
}

textarea {
    &.form-control-1 {
        min-height: 150px;
    }
}