@import "./Colors.scss";

.home {
    min-height: 100vh;
    .background-1 {
        background: rgb(74,91,233);
        background: linear-gradient(90deg, rgba(74,91,233,1) 0%, rgba(32,222,190,1) 100%);
    }

    .background {
        position: absolute;
        top:0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: url(../../res/background-1.png);
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        background-attachment:fixed;
        
    }

    .background-2 {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgb(95,103,233);
        background: linear-gradient(90deg, rgba(95,103,233,1) 0%, rgba(82,153,219,1) 100%);
        opacity: 0.8;
    }
    .main {
        padding:40px;
        padding-left: 60px;
        padding-right: 60px;
        position: relative;

        .vertical-divider {
            width: 1.5px;
            height: 40px;
            margin-left: 10px;
            margin-right: 10px;
            background-color: white;
            display: block;
        }
        
        



.logo {
    width: 200px;
}

   .navbar {
       .nav-item {
           a {
               color:white;
           }

           .vertical-divider {
               width: 1.5px;
               height: 40px;
               margin-left: 10px;
               margin-right: 10px;
               background-color: white;
               display: block;
           }
       }
   }

   .presentation {

    .section-1 {
        min-height: 500px;
    }

       .col-sm-6 {
           padding: 0;
           h1 {
            color:white;
        }
        
           h2 {
               color:white;
           }

           p {
               margin-bottom: 0;
               color:white;
           }
       }

       

       .relative {
           position: relative;
       }

       .model {
           position: absolute;
           top:0;
           left: 0;
           width: 100%;
           height: 100%;
           transform: translate(60px,40px);
        background-image: url("../../res/model.png");
        background-position: right bottom;
        background-size:contain;
        background-repeat: no-repeat;
       }

       .model-2 {
        position: absolute;
        bottom: 0 !important;
        right: 0;
        width: 100%;
        height: 100%;
     background-image: url("../../res/model2.png");
     background-position: bottom !important;
     background-size:contain;
     background-repeat: no-repeat;
     transform: translate(60px, 40px);
       }

       .input-group {
           border-radius: 30px !important;
           overflow: hidden !important;
           width: 100%;

        .input-group-prepend{
            border:none !important;
            padding: 0;
           .input-group-text {
               background-color:white;
               border:none !important;
           }
        }

        .form-control {
            border:none !important;
            min-height: 40px;

            outline: none !important;
            box-shadow: none !important;

            &:hover {
                outline: none !important;
            box-shadow: none !important;
            }

            &:focus {
                outline: none !important;
            box-shadow: none !important;
            }
        }
       }
   }
    }

    .container-fluid {
        padding: 40px;
        padding-left: 60px;
        padding-right: 60px;
    }

    .container {
        padding: 40px;
       
    }

    .margin-special {
        margin-top: 80px;
        margin-bottom: 80px;
    }

    .special-container-2 {
        padding:120px;

        .special-card-2 {
            padding:40px;
            border-radius: 30px;
            background-color: rgb(212,253,248);

            .header-1 {
                color:rgb(72,107,231);
            }

            .form-control {
                border:none !important;
                border-radius: 30px;
                

                &::placeholder {
                    color:rgb(72,107,231);
                }
            }

            .input-container {
                background-color: white;
                border-radius: 10px;
                padding:5px;
                .form-control {
                    width: 260px;
                    border:none !important;
                    outline: none !important;
                    box-shadow: none !important;

                    &:focus {
                        outline: none !important;
                    box-shadow: none !important;
                    }

                    &:hover {
                        outline: none !important;
                    box-shadow: none !important;
                    }
                }
            }
            
        }
    }
    

    .special-container {
        padding-top: 40px;
        padding-bottom: 40px;

        background-color: rgb(212,253,248);

        h5 {
            color:rgb(76,86,235);
        }

        .special-card {
            background-color:white;
            padding:0 !important;
            display: flex;
            justify-content: flex-start;
            flex-wrap: nowrap !important;
            border-top-left-radius: 30px;
            border-bottom-left-radius: 30px;
            overflow-x: auto !important;
            
            

            .special-card-item {
                width: 350px !important;
                min-width: 350px !important;
                padding: 40px;
                .wrapper {
                    position: relative;
                    height: 180px;
                    
                    border-radius: 10px;

                    .image {
                        position: absolute;
                        top:0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                    }
                }


                .date {
                    font-size: 12px;
                }

                .title {
                    p {
                        font-weight: bold;
                        margin-bottom: 0;
                    }
                }

                .link {
                    color:rgb(76,86,235);
                    cursor: pointer;
                    display: block;
                }
            }
        }
    }

    .card-tag-2 {
        .image {
            width: 60px;
        }

        h5 {
            font-size: 20px;
            font-weight: bold;
            color:rgb(76,86,235)
        }

        p {
            font-size: 12px;
        }
    }

    .card-tag {
        padding: 20px;
        border-radius: 40px;

        min-height: 260px;

        .tag-1 {
            height: 100px;
        }

        .tag-2 {
            height: 100px;
        }
        .tag-3 {
            height: 100px;
        }

        h5 {
            color:rgb(76,86,235);
        } 
        p{
            font-size: 14px;
        }
    }

    .about-us {
        display: none;
        h2 {
            color:white;
        }

        p {
            color: white;
          
        }
    }

    .about-us-d {
        display: none;
        h2 {
            color:rgb(76,86,235)
        }
        
    }


    .community {

        h2 {
            color:rgb(76,86,235)
        }
        .col-sm-6 {
            
            position: relative !important;
        }

        .section-1 {
            min-height: 500px;
        }

        .section-2 {
            height: 500px;
        }

        .graphic-1 {
            background-image: url("../../res/graphic_1.png");
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
            position: absolute;
            top:0;
            left: 40px;
            height: 100%;
            width: 100%;
        }

        .graphic-2 {
            background-image: url("../../res/graphic_2.png");
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
            position: absolute;
            top:0;
            right: 40px;
            height: 100%;
            width: 100%;
        }
    }
}


.footer {
    background-color: rgb(77,83,236);
    padding: 40px;

    .logo {
        width: 200px;
    }

     p {
         color:white;
     }
     hr {
         background-color: white;
     }

     a {
         display: flex;
         color: white !important;
         cursor: pointer;
         text-decoration: none !important;
     }

     .special-link {
         color:rgb(38,245,220);
         display: inline;
     }
}

.fa {
    padding: 20px;
    font-size: 30px;
    width: 50px;
    text-align: center;
    text-decoration: none;
  }
  
  /* Add a hover effect if you want */
  .fa:hover {
    opacity: 0.7;
  }
  
  /* Set a specific color for each brand */
  
  /* Facebook */


  .admin-panel {
      padding: 40px;
      
      h2 {
        color:rgb(76,86,235)
      }

      .btn-back {
          i {
              font-size: 40px;
              color:rgb(76,86,235)
          }
      }

      .admin-sections {
        min-height: 400px;

        .circle-feature {
            width: 60px;
            height: 60px;
            background-color:rgb(76,86,235);
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            b {
                color:white;
            }
        }


        .header-1 {
            padding: 10px;
            border-bottom: 3px solid rgba(32,222,190,1);
            color:rgba(32,222,190,1);
        }

        .reference-card {
            padding: 20px;
            border-radius: 40px;
            border:1.5px solid rgba(32,222,190,1);
        }

        .image-select {
            position: relative;

            .custom-file {
                position: absolute;
                top:0;
                left: 0;
                height: 100% !important;
                border: none !important;
                outline: none !important;
                box-shadow: none !important;
                background-color: transparent !important; 
                cursor: pointer;
    
                .custom-file-input {
                    height: 100%;
                    border: none !important;
                    background-color: transparent !important;
                    cursor: pointer;
                    outline: none !important;
                box-shadow: none !important;
                }
                .custom-file-label {
                    height:100% !important;
                    border: none !important;
                    background-color: transparent !important;
                    cursor: pointer;
                    outline: none !important;
                box-shadow: none !important;
    
                    &:after {
                        content:"";
                        background-color: transparent;
                        border: none !important;
                        cursor: pointer;
                        outline: none !important;
                box-shadow: none !important;
                    }
                }
            }
        }

        .form-control {
            min-width: 300px;
        }

        .section-button {
            padding: 10px;
            min-width: 150px;
            cursor: pointer;
            transition: 0.3s;
            &.patients {
                h5 {
                color:$lightGrey;
                text-align: center;
                }

                border-bottom: 3px solid $lightGrey;
                &.active {
                    h5 {
                        color:rgba(32,222,190,1);
                        text-align: center;
                    }

                    border-bottom: 3px solid rgba(32,222,190,1);
                }
            }
            &.dentists {
                h5 {
                color:$lightGrey;
                text-align: center;
                }
                border-bottom: 3px solid $lightGrey;
                &.active {
                    h5 {
                        color:rgb(76,86,235);
                        text-align: center;
                    }

                    border-bottom: 3px solid rgb(76,86,235);
                }
            }
        }
      }


      .admin-panel-auth {
          width: 100%;
          display: flex;
          justify-content: center;
          min-height: 400px;
          align-items: flex-start;

          .admin-panel-form {
              padding: 40px;
              min-width: 400px;
              background-color: white;
              border-radius: 40px;
          }
      }
  }


  @media screen and (max-width:1200px){
      .dentists-cards {
          .card-tag-2 {
              display: flex;
              justify-content: center;
              flex-direction: column;
              align-items: center !important;

              h5 {
                  margin-top: 10px;
                  text-align: center;
              }

              p {
                  text-align: center;
              }
          }
      }
  }


@media screen and (max-width:800px){

    .col-sm-6 {
        padding: 0;
    }

    .col-sm-4 {
        padding: 0;
    }

    
    .presentation {
        
    }

    .about-us {
        display: block !important;
        h2 {
            color:white;
        }

        p {
            color: white;
          
        }
    }

    .home {
        
        .main {
            padding:20px !important;
            a {color: white !important;}

            .model-2 {
                display: none;
            }
        }

        .container {
            padding: 20px !important;
        }
    }

    .community {
        .section-1 {
            display: block !important;
            min-height: auto !important;
        }

        .section-2 {
            height: 200px !important;
        }

        .graphic-1 {
            background-image: url("../../res/graphic_1.png");
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
            position: absolute;
            top:0 !important;
            left: 0 !important;
            height: 100%;
            width: 100%;
        }

        .graphic-2 {
            background-image: url("../../res/graphic_2.png");
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
            position: absolute;
            top:0;
            right:0 !important;
            height: 100%;
            width: 100%;
        }
    }


    .special-container-2 {
        padding: 20px !important;

        .input-container {
            .form-control {
                width: 200px !important;
                border:none !important;
                outline: none !important;
                box-shadow: none !important;

                &:focus {
                    outline: none !important;
                box-shadow: none !important;
                }

                &:hover {
                    outline: none !important;
                box-shadow: none !important;
                }
            }

            .btn-custom-2 {
                span {
                    display: none !important;
                }
                 i {
                     margin-left: 0 !important;
                 }
              }
        }
    }

    
    .footer {
        padding: 0 !important;
        padding-bottom: 20px !important;
        .flex-sb {
            flex-direction: column;
        }
    }

    
}
  